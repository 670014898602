/* Ensure cards have the same height */
.custom-carousel .slick-slide {
    display: flex; /* Aligns cards properly */
    justify-content: center;
    align-items: center;
    height: auto; /* Ensures dynamic height if content is longer */
  }
  
  .custom-carousel .slick-slide div {
    height: 100%; /* Matches height of the tallest card */
  }
  
  /* Space for arrows */
  .custom-carousel .slick-prev,
  .custom-carousel .slick-next {
    z-index: 10; /* Ensure arrows are above content */
    width: 60px; /* Larger arrows for visibility */
    height: 60px;
    /* border-radius: 50%; */
    /* background-color: rgba(0, 0, 0, 0.5); */
    color: rgb(244, 98, 0);
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translateY(-50%);
  }
  
  .custom-carousel .slick-prev {
    left: -50px; /* Push left arrow outside the content */
  }
  
  .custom-carousel .slick-next {
    right: -100px; /* Push right arrow outside the content */
  }
  
  /* Style dots */
  .custom-carousel .slick-dots {
    bottom: -30px; /* Move dots below the cards */
  }
  
  .custom-carousel .slick-dots li button {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #697455;
    border: none;
  }
  
  .custom-carousel .slick-dots li.slick-active button {
    background-color: rgb(244, 98, 0); /* Active dot color */
  }
  